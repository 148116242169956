<template>
  <div class="menu_wrap">
    <div class="overlay">
      <ul class="menu">
        <li class="menu__title">T-SHIRTS</li>
        <li class="menu__item">
          <router-link to="/tailored-tshirts" @click="onClick"
            >テイラーTシャツ</router-link
          >
        </li>
        <li class="menu__item">
          <router-link to="/customized-tshirts" @click="onClick"
            >カスタマイズTシャツ</router-link
          >
        </li>
        <li class="menu__title">SERVICE</li>
        <li class="menu__item">
          <router-link to="/tryon" @click="onClick">試着サービス</router-link>
        </li>
        <li class="menu__item">
          <router-link to="/resize" @click="onClick"
            >お直しサービス</router-link
          >
        </li>
        <li class="menu__item">
          <router-link to="/members" @click="onClick">会員サービス</router-link>
        </li>
        <li class="menu__title">GUIDE</li>
        <li class="menu__item">
          <router-link to="/shopping" @click="onClick"
            >ショッピング</router-link
          >
        </li>
        <li class="menu__item">
          <router-link to="/order-tshirts" @click="onClick"
            >オーダーTシャツ</router-link
          >
        </li>
        <li class="menu__item">
          <router-link to="/fabrics" @click="onClick">生地・カラー</router-link>
        </li>
        <li class="menu__item">
          <router-link to="/Q&amp;A" @click="onClick">Q&amp;A</router-link>
        </li>
        <li class="menu__title">CONTACT</li>
        <li>
          <router-link to="/contact" @click="onClick">お問い合わせ</router-link>
        </li>
        <li>
          <router-link to="/company" @click="onClick">会社概要</router-link>
        </li>
        <li><router-link to="/" @click="onClick">トップページ</router-link></li>
      </ul>
      <div class="others">
        <a
          class="others__item"
          href="https://www.facebook.com/orderTshirts.msfabric/"
          target="_blank"
          ><span class="material-icons">facebook</span></a
        >
        <a
          class="others__item"
          href="https://cart.ec-sites.jp/sp_step2/pc/kago/es_shop_id/4777/"
          ><span class="material-icons">shopping_cart</span></a
        >
        <a
          class="others__item"
          href="https://cart.ec-sites.jp/sp_step2/mypage/top/shid/4777/"
          ><span class="material-icons">login</span></a
        >
        <a class="others__item" href="mailto:info@ms-fabric.com"
          ><span class="material-icons">email</span></a
        >
      </div>
    </div>
  </div>
</template>

<script>
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'

export default {
  data() {
    return {
      menuActive: false,
    }
  },
  methods: {
    onClick() {
      this.$emit('isClicked', this.menuActive)
    },
  },
  mounted() {
    const modal = document.querySelector('.menu')
    disableBodyScroll(modal)
  },
  beforeUnmount() {
    clearAllBodyScrollLocks()
  },
}
</script>

<style lang="scss" scoped>
.overlay {
  width: 100%;
  height: calc(100vh - 80px);
  background-color: #000;
  padding-top: 50px;
  position: fixed;
  z-index: 80;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu {
  width: 100%;
  height: calc(100% - 100px);
  list-style: none;
  text-align: center;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 90;

  & > * {
    margin-bottom: 20px;
    display: block;
  }

  &__title {
    width: 180px;
    letter-spacing: 2px;
    padding: 30px 0 10px;
    font-size: 14px;
    font-weight: 300;
    border-bottom: 1px solid var(--color-dark);
  }
}

.others {
  width: 100%;
  height: 80px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 95;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1;

  &__item {
    text-align: center;
    height: 100%;
    background-color: var(--color-dark);

    display: grid;
    align-items: center;
  }
}
</style>
