<template>
  <!-- <Loading /> -->
  <div class="top-content">
    <div class="sale-info">
      <p>
        当店は2024年5月10日リニューアルオープンのため新しいURLに移行しました。
      </p>
    </div>
    <div class="tailor-img">
      <div class="tailor">
        <div class="title">
          <div>Tailored</div>
          <div>T-shirts</div>
        </div>
        <h2 class="copy">
          究極のTシャツ。<br />それはあなたのために作られたTシャツ
        </h2>
        <div class="btn">
          <a href="https://mnsfabric.com/">
            <button>新しいショップに行く</button>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="top-content custom-bgc">
    <div class="customize-img">
      <div class="customize">
        <div class="title">
          <div>Customized</div>
          <div>T-shirts</div>
        </div>
        <h2 class="copy">
          こだわりのTシャツ。<br />だから細部まで自分仕様にカスタマイズ
        </h2>
        <div class="btn">
          <a href="https://mnsfabric.com/">
            <button>新しいショップに行く</button>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="top-content service">
    <h3 class="service__title">Our services</h3>
    <div class="service__content">
      <div class="service__content--item tryon">
        <div class="service__content--item--text">
          <h4>試着サービス</h4>
          <p>
            商品を製作する前に実際に試着する事により、仕上がりを想定いただけます。
          </p>
        </div>
      </div>
      <div class="service__content--item fix">
        <div class="service__content--item--text">
          <h4>お直しサービス</h4>
          <p>
            着用後に変更したい箇所がありましたら、次回購入時にお直しいたします。
          </p>
        </div>
      </div>
      <div class="service__content--item reproduction">
        <div class="service__content--item--text">
          <h4>お持ちのTシャツを再現</h4>
          <p>
            お気に入りのTシャツのサイズを元に、高品質な生地で新たに製作いたします。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { onMounted } from '@vue/runtime-core'
import Loading from '../components/Loading.vue'

export default {
  components: { Loading },
  setup() {
    onMounted(() => {
      gsap.registerPlugin(ScrollTrigger)

      const tailor = () => {
        gsap.fromTo(
          '.tailor',
          { y: 150, autoAlpha: 0 },
          { y: 0, autoAlpha: 1, duration: 0.5 }
        )
      }
      tailor()

      const customize = () => {
        gsap.fromTo(
          '.customize',
          { y: 150, autoAlpha: 0 },
          {
            y: 0,
            autoAlpha: 1,
            duration: 0.5,
            scrollTrigger: { trigger: '.customize', start: 'center bottom' },
          }
        )
      }
      customize()

      const service = () => {
        gsap.fromTo(
          '.service__content--item',
          { y: 150, autoAlpha: 0 },
          {
            y: 0,
            autoAlpha: 1,
            duration: 0.3,
            scrollTrigger: {
              trigger: '.service__content--item',
              start: 'center bottom',
            },
            stagger: { from: 'start', amount: 0.5 },
          }
        )
      }
      service()
    })

    const router = useRouter()
    const toTailor = () => {
      router.push({ name: 'Tailor' })
    }
    const toRepeat = () => {
      router.push({ name: 'Repeat' })
    }
    const toFabric = () => {
      router.push({ name: 'Fabrics' })
    }
    const toContact = () => {
      router.push({ name: 'Contact' })
    }
    const toTryon = () => {
      router.push({ name: 'Tryon' })
    }
    const toResize = () => {
      router.push({ name: 'Resize' })
    }
    const toCustom = () => {
      router.push({ name: 'Customize' })
    }

    return {
      toFabric,
      toContact,
      toTailor,
      toRepeat,
      toTryon,
      toResize,
      toCustom,
    }
  },
}
</script>

<style lang="scss" scoped>
.top-content {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.custom-bgc {
  background-color: #6b6b6b;
}
.tailor-img,
.customize-img {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tailor-img {
  height: calc(100vh - 100px);
  background-image: url('../assets/img/tailor-top-bg.jpg');
  background-size: cover;
  background-position: 50% 0;
  background-repeat: no-repeat;

  @media screen and (max-width: 767px) {
    height: calc(100vh - 50px);
  }
}
.customize-img {
  height: 100vh;
  background-image: url('../assets/img/customize-top-bg.jpg');
  background-size: cover;
  background-position: 50% 0;
  background-repeat: no-repeat;
}

.tailor,
.customize {
  width: 100%;
}

.customize {
  & .copy {
    color: var(--color-heavy);
    font-weight: 400;
  }
}

.title {
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 3rem;
  font-family: 'objektiv-mk1', sans-serif;

  & div:first-child {
    font-size: 8rem;
    font-weight: 800;
    margin-bottom: -1.5rem;

    @media screen and (max-width: 600px) {
      font-size: 6rem;
    }

    @media screen and (max-width: 425px) {
      font-size: 5rem;
    }
  }

  & div:last-child {
    font-size: 5.5rem;
    font-weight: 800;

    @media screen and (max-width: 600px) {
      font-size: 4rem;
    }
  }
}

.tailor .title div:first-child {
  color: var(--color-tailor-light);
}

.customize .title div:first-child {
  color: var(--color-custom-light);
}

.copy {
  text-align: center;
  font-size: 1.8rem;
  font-weight: 300;
  letter-spacing: 0.2rem;
  line-height: 2;
  margin-bottom: 5rem;

  @media screen and (max-width: 600px) {
    font-size: 1.8rem;
  }
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }

  & button {
    width: 250px;
    margin: 0 1rem;
    margin-bottom: 2rem;
    background-color: rgba(0, 0, 0, 0.4);
    border: 0;
    color: var(--color-light);
    padding: 12px 20px;
    border: 1px solid var(--color-medium);
    border-radius: 100px;
    cursor: pointer;
    transition: all 0.3s ease-out;

    &:hover {
      border: 1px solid var(--color-light);
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}

.service {
  max-width: 1240px;
  padding: 10rem 2rem 8rem;
  border-bottom: 1px solid #555;

  @media screen and (max-width: 767px) {
    padding-bottom: 3rem;
  }

  &__title {
    font-size: 2.8rem;
    text-transform: uppercase;
    font-family: 'objektiv-mk1', sans-serif;
    font-weight: 700;
    letter-spacing: 0.2rem;
    margin-bottom: 9rem;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      width: 100%;
    }

    &--item {
      width: 30%;
      height: 500px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
      cursor: pointer;

      @media screen and (max-width: 1023px) {
        height: 400px;
      }

      @media screen and (max-width: 767px) {
        width: 100%;
        margin-bottom: 5rem;
      }

      &--text {
        width: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        padding: 2rem 2rem;
      }

      &--text h4 {
        text-align: center;
        font-size: 16px;
        font-weight: 300;
        margin-bottom: 1rem;
      }
    }
  }
}

.tryon {
  background-image: url('../assets/img/tryon-top.jpg');
  background-size: cover;
  background-position: center center;
  transition: all 0.3 ease;
}
.fix {
  background-image: url('../assets/img/fix-top.jpg');
  background-size: cover;
  background-position: center center;
}
.reproduction {
  background-image: url('../assets/img/reproduction-top.jpg');
  background-size: cover;
  background-position: center center;
}

.leaflet {
  max-width: 1240px;
  padding: 10rem 2rem 5rem;

  @media screen and (max-width: 767px) {
    padding-bottom: 3rem;
  }

  @media screen and (max-width: 425px) {
    padding-top: 7rem;
    padding-bottom: 0;
  }

  &__title {
    font-size: 2.8rem;
    text-transform: uppercase;
    font-family: 'objektiv-mk1', sans-serif;
    font-weight: 700;
    letter-spacing: 0.2rem;
  }
  & h4 {
    font-size: 1.8rem;
    margin-bottom: 3rem;
  }

  & img {
    width: 50%;

    @media screen and (max-width: 1023px) {
      width: 60%;
    }

    @media screen and (max-width: 767px) {
      width: 80%;
    }

    @media screen and (max-width: 425px) {
      width: 100%;
    }
  }

  & p {
    text-align: center;
  }

  & .btn {
    margin-top: 4rem;
    & button {
      width: 240px;
    }
  }
}

.sale-info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 7px;
  background-color: #b47f32;

  & p {
    width: 80%;
    font-size: 1.5rem;
    font-weight: 300;
    text-align: center;
  }
}
</style>
