<template>
  <div class="full">
    <div class="container">
      <header class="header">
        <div class="header__logo">
          <router-link to="/"
            ><img src="../assets/img/ms_logo.svg" alt=""
          /></router-link>
        </div>
        <nav class="header__nav">
          <a href="https://mnsfabric.com/">
            NEW SHOP
          </a>
        </nav>
      </header>
    </div>
  </div>
</template>

<script>
import Menu from './Menu.vue'
export default {
  data() {
    return {
      isActive: false,
    }
  },
  components: {
    Menu,
  },
  methods: {
    showMenu() {
      this.isActive = !this.isActive
    },
    changeStatus(menuActive) {
      this.isActive = menuActive
    },
  },
}
</script>

<style lang="scss" scoped>
.full {
  height: 120px;

  @media screen and (max-width: 767px) {
    height: 80px;
  }
}
.container {
  height: 100%;
}
.header {
  height: 100%;
  font-family: 'objektiv-mk1', sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1440px;
  transition: height 0.3s ease-out;

  &__logo {
    width: 3.5rem;
    height: 3.5rem;
  }

  &__nav {
    display: flex;
    align-items: center;

    & > * {
      margin-left: 35px;
      font-family: var(--font-title);
      letter-spacing: 1px;
      font-size: 13px;
      font-weight: 300;
      display: inline-block;
    }

    & a:not(.header__nav--icon)::after {
      content: '';
      background-color: var(--color-medium);
      width: 0%;
      height: 1px;
      display: block;
      margin-left: 50%;
      transform: translate(-50%, 5px);
      opacity: 0;
    }

    & a:hover::after {
      transition: all 0.3s ease-out;
      width: 100%;
      opacity: 1;
    }

    & a:last-of-type {
      margin-left: 10px;
    }

    &--title {
      display: flex;
      align-items: center;
      font-weight: 200;
    }

    &--icon {
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--color-dark);
      border-radius: 50%;
      cursor: pointer;
      transition: all 0.3s ease-out;

      & .material-icons {
        font-size: 20px;
        text-align: center;
      }

      &:hover {
        background-color: #666;
      }
    }
  }
}

// Navigation for smart phone

.header_navBtn {
  width: 30px;
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  z-index: 100;

  &__line {
    width: 100%;
    height: 2px;
    background-color: var(--color-light);
    transition: all 0.2s ease-out;
  }
}

.active {
  .header_navBtn__line:nth-child(1) {
    transform: translateY(12px) rotate(225deg);
  }
  .header_navBtn__line:nth-child(2) {
    opacity: 0;
  }
  .header_navBtn__line:nth-child(3) {
    transform: translateY(-11px) rotate(-225deg);
  }
}

.modal-menu {
  position: absolute;
  top: 0;
  left: 0;
}

// Animation for modal menu
.fade-enter-active {
  animation: fade-in 0.3s;
}
.fade-leave-active {
  animation: fade-in 0.3s reverse;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
