import { createRouter, createWebHistory } from 'vue-router'
import Top from '../views/Top.vue'

const routes = [
  {
    path: '/',
    name: 'Top',
    component: Top,
  },
  {
    path: '/tailored-tshirts',
    name: 'Tailor',
    component: () => import('../views/Tailor.vue'),
  },
  {
    path: '/repeat',
    name: 'Repeat',
    component: () => import('../views/Repeat.vue'),
  },
  {
    path: '/customized-tshirts',
    name: 'Customize',
    component: () => import('../views/Customize.vue'),
  },
  {
    path: '/tryon',
    name: 'Tryon',
    component: () => import('../views/Tryon.vue'),
  },
  {
    path: '/resize',
    name: 'Resize',
    component: () => import('../views/Resize.vue'),
  },
  {
    path: '/members',
    name: 'Members',
    component: () => import('../views/Members.vue'),
  },
  {
    path: '/shopping',
    name: 'Shopping',
    component: () => import('../views/Shopping.vue'),
  },
  {
    path: '/order-tshirts',
    name: 'Order',
    component: () => import('../views/Order.vue'),
  },
  {
    path: '/fabrics',
    name: 'Fabrics',
    component: () => import('../views/Fabrics.vue'),
  },
  {
    path: '/Q&A',
    name: 'Q&A',
    component: () => import('../views/Q&A.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue'),
  },
  {
    path: '/company',
    name: 'Company',
    component: () => import('../views/Company.vue'),
  },
  {
    path: '/payment',
    name: 'Payment',
    component: () => import('../views/Payment.vue'),
  },
  {
    path: '/thanks',
    name: 'Thanks',
    component: () => import('../views/Thanks.vue'),
  },
]

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition
  } else {
    return { top: 0 }
  }
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior,
})

export default router
