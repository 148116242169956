<template>
  <transition name="fade">
    <div v-if="isOnload" class="loading">
      <img class="loading__logo" src="../assets/img/ms_logo.svg" alt="" />
      <div class="loading__subTitle title-en-light">Order T-shirts store</div>
      <div class="loading__three-dots">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>
  </transition>
</template>

<script>
import { ref } from '@vue/runtime-core'
export default {
  setup() {
    let isOnload = ref(true)

    window.onload = () => {
      setTimeout(() => {
        isOnload.value = false
      }, 2000)
    }
    return { isOnload }
  },
}
</script>

<style lang="scss" scoped>
.loading {
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 100;

  &__logo {
    height: 55px;
    animation: logo-animation 1s ease;
  }

  &__subTitle {
    color: #fff;
    text-transform: uppercase;
    font-size: 11px;
    margin-top: 20px;
    letter-spacing: 2px;
    animation: subTitle-animation 1s ease;
  }
  &__three-dots {
    & > div {
      width: 8px;
      height: 8px;
      background-color: #999;
      border-radius: 50%;
      display: inline-block;
      margin: 35px 3px 0;
      animation: bounceDelay 1.4s infinite;
    }
    & .bounce1 {
      animation-delay: -0.32s;
    }
    & .bounce2 {
      animation-delay: -0.16s;
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease-out;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-100%);
}

@keyframes logo-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes subTitle-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes bounceDelay {
  0%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
  80% {
    transform: scale(0);
  }
}
</style>
