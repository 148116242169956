<template>
  <div class="full">
    <div class="container">
      <footer class="footer">
        <div class="shop-info">
          <h1 class="shop-info__name">
            m&amp;s Fabric
            <span class="shop-info__name--sub">Order T-shirts Store</span>
          </h1>
        </div>
      </footer>
      <address class="copy">
        Copyright © 2021 All Rights Reserved m&amp;s Fabric
      </address>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.full {
  height: 300px;
  margin-top: 5rem;

  @media screen and (max-width: 767px) {
    height: 150px;
  }
}
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  @media screen and (max-width: 767px) {
    justify-content: center;
    margin-top: 10px;
  }
}
.shop-info__name {
  font-size: 2.9rem;
  letter-spacing: 1px;
  display: inline-block;
  line-height: 1;
  font-weight: 500;
  font-family: 'objektiv-mk1', sans-serif;

  &--sub {
    display: block;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    font-size: 1.1rem;
    text-align: center;
    margin-top: 5px;
    font-family: 'objektiv-mk1', sans-serif;
  }
}
.shop-info__others {
  margin-top: 20px;

  @media screen and (max-width: 767px) {
    display: none;
  }
  & ul {
    list-style-type: none;
    & li {
      display: flex;
      align-items: center;
      font-family: 'objektiv-mk1', sans-serif;
    }
    & li a {
      font-size: 13px;
      line-height: 2;
    }
    & li .material-icons {
      font-size: 14px;
      padding-right: 5px;
    }
  }
}
.shop-menu {
  display: grid;
  grid-template-columns: repeat(4, max-content);
  grid-gap: 50px;

  @media screen and (max-width: 1023px) {
    grid-gap: 20px;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }

  &__item {
    & ul {
      list-style-type: none;

      & li:first-child {
        font-size: 12px;
        text-transform: uppercase;
        margin-bottom: 25px;
        letter-spacing: 1.5px;
        font-weight: 300;
        font-family: 'objektiv-mk1', sans-serif;
      }

      & li {
        margin-bottom: 10px;
      }

      & li a {
        font-size: 12.5px;
        letter-spacing: 1.5px;
        display: block;
        margin-bottom: 5px;
        line-height: 1;
      }

      & li:not(:first-child)::after {
        content: '';
        background-color: var(--color-medium);
        width: 0%;
        height: 1px;
        display: block;
        opacity: 0;
      }

      & li:not(:first-child):hover::after {
        transition: all 0.3s ease-out;
        width: 100%;
        opacity: 1;
      }
    }
  }
}
.copy {
  font-style: normal;
  font-size: 1.2rem;
  font-family: 'objektiv-mk1', sans-serif;

  @media screen and (max-width: 767px) {
    text-align: center;
    margin: 50px 0 0;
  }
}
</style>
